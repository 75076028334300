import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizFollowRestrictions1', 'quizFollowRestrictions2', 'quizFollowRestrictions3', 'quizFollowRestrictions4', 'quizFollowRestrictions5', 'quizFollowRestrictions6', 'quizFollowRestrictions7', 'quizFollowRestrictions8', 'quizFollowRestrictions9'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "helping-your-teen-follow-the-local-regulations-and-restrictions"
    }}>{`Helping your teen follow the local regulations and restrictions`}</h2>
    <p>{`Some teens may not want to follow the local rules, or may not take the pandemic seriously. How should parents respond in this situation?
Indicate below whether you think each of the approaches would be helpful or unhelpful.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/4-argument.svg" alt="having argument" mdxType="SingleImage" />
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions1" singleAnswer={true} question="Have an open conversation with your teen to understand what is stopping them from following the rules." fallbackResponse={<div>
        <b>Helpful</b>. This is a great starting point. In order to work through the problem with your teen, you need to
        first understand what is going on for them.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions2" singleAnswer={true} question="Work together to problem-solve any barriers to following the rules." fallbackResponse={<div>
        <b>Helpful</b>. Once you understand what is getting in the way of them following the rules, work with your teen
        to problem-solve the issues. For advice on teaching your teen problem-solving skills, check out the{' '}
        <GoTo to="/dashboard" mdxType="GoTo">Partners in Problem Solving</GoTo> module.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions3" singleAnswer={true} question="Brainstorm ways to make the restrictions more bearable, while keeping everyone safe." fallbackResponse={<div>
        <b>Helpful</b>. Your teen is more likely to follow the rules if they can find creative ways to continue doing
        the activities that are important to them. You can help your teen by brainstorming ways to make the restrictions
        more bearable.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions4" singleAnswer={true} question="Impose stricter rules and punishments." fallbackResponse={<div>
        <b>Not helpful</b>. The ultimate aim is to help your teen stick to the rules that are important for their own
        and others’ safety. Imposing stricter rules may make your teen even less likely to follow them. Ensure any rules
        and associated consequences are fair. Avoid imposing punishments that may deter your teen from being truthful.
        Make it clear that it is important to be honest about being exposed to any risk of infection, even if they have
        broken the rules.
      </div>} answers={[{
        name: 'Helpful',
        correct: false
      }, {
        name: 'Not Helpful',
        correct: true
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions5" singleAnswer={true} question="Explain that rules are there to save lives, are temporary, and are not a punishment." fallbackResponse={<div>
        <b>Helpful</b>. Remind your teen about the reasons behind the rules, including that it is not about punishing
        them. Try to help your teen see the bigger picture.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions6" singleAnswer={true} question="Explain the rules in a way that your teen will understand." fallbackResponse={<div>
        <b>Helpful</b>. Make sure that your teen understands the rules, by explaining them in a clear way. Check in on
        your teen’s understanding if you’re unsure.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions7" singleAnswer={true} question="Encourage your teen to raise any concerns or challenges they have in adapting to the pandemic." fallbackResponse={<div>
        <b>Helpful</b>. Keep the conversations going. Encourage your teen to come to you to discuss any challenges they
        are having in following the rules or adapting to the pandemic.
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions8" singleAnswer={true} question="Try to establish a clear sense of what your teen needs and wants." fallbackResponse={<div>
        <b>Helpful</b>. Your teen may struggle to follow the rules if they feel they aren’t getting their needs met. Try
        to understand what they want and need at this time, and respond with empathy
      </div>} answers={[{
        name: 'Helpful',
        correct: true
      }, {
        name: 'Not Helpful',
        correct: false
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizFollowRestrictions9" singleAnswer={true} question="Leave them to make their own decisions, so they can learn to think independently." fallbackResponse={<div>
        <b>Not helpful</b>. Although it is important for your teen to develop independence, they still need guidance and
        support from you, especially when confronted with challenges they’ve never faced before. During this time, try
        to support your teen to make appropriate and safe choices.
      </div>} question="Leave them to make their own decisions, so they can learn to think independently." answers={[{
        name: 'Helpful',
        correct: false
      }, {
        name: 'Not Helpful',
        correct: true
      }, {
        name: "Don't know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  <b>Here are some other tips to consider:</b>
  <ul>
    <li>
      Be a good role model:
      <br />- Follow the local regulations and restrictions yourself.
      <br />- Model flexibility and problem-solving by showing your teen how you adjust your daily life in response to
      changing regulations and restrictions.
    </li>
    <li>
      If your teen is sceptical about the risks of the pandemic, discuss information from reliable sources with them.
    </li>
  </ul>
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      